import { usePlausible as useNextPlausible } from 'next-plausible'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { PLAUSIBLE_EVENTS, type PlausibleEvent } from '../constants/events'

export default function useAnalytics() {
  const nextPlausible = useNextPlausible()
  const { asPath } = useRouter()

  const sendAnalyticsEvent = useCallback(
    (name: PlausibleEvent, props: Record<string, string> = {}) => {
      //* Stop developers from doing boo boos
      if (process.env.NODE_ENV == 'development' && !PLAUSIBLE_EVENTS.includes(name)) {
        throw new Error(`Event ${name} is not defined in PLAUSIBLE_EVENTS`)
      }
      nextPlausible<PlausibleEvent>(name, {
        u: `${window.location.origin}${asPath}`,
        props,
      })
    },
    [nextPlausible, asPath],
  )

  return sendAnalyticsEvent
}
