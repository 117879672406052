import {
  BannerContainer,
  Footer,
  Header,
  PageProps,
  WWW_URL,
  cgw,
  getLocaleHeaders,
  getTranslation,
  useInterceptor,
} from '@instabox/consumer-web-shared'
import { Container, PageContainer, Styles } from '@instabox/consumer-web-ui'
import { captureException } from '@sentry/nextjs'
import PlausibleProvider from 'next-plausible'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { IntlProvider } from 'react-intl'

import da from '../locale/da.json'
import de from '../locale/de.json'
import en from '../locale/en.json'
import nl from '../locale/nl.json'
import no from '../locale/no.json'
import sv from '../locale/sv.json'
import PageTracking from '../components/PageTracking'

function MyApp({ Component, pageProps }: AppProps) {
  const {
    pageLinks,
    meta_title,
    meta_description,
    translations,
    market,
    pageContainerVariant,
    fullBleed = false,
    banners = [],
    noIndex = false,
  } = pageProps as PageProps
  const { locale, asPath, pathname } = useRouter()

  const messages = useMemo(() => {
    switch (locale) {
      case 'no-NO':
        return no
      case 'sv-SE':
        return sv
      case 'da-DK':
        return da
      case 'de-DE':
        return de
      case 'nl-NL':
        return nl
      default:
        return en
    }
  }, [locale])

  useInterceptor(cgw, config => {
    config.headers = {
      ...config.headers,
      ...getLocaleHeaders(locale),
    }
    return config
  })

  const metaTitle = getTranslation('meta_title', translations, meta_title)
  const metaDescription = getTranslation('meta_description', translations, meta_description)
  const canonicalUrl = `${WWW_URL}/${locale}${asPath}`

  return (
    <PlausibleProvider domain="instabox.io" manualPageviews>
      <Head>
        <link
          rel="shortcut icon"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAAEEfUpiAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAIKADAAQAAAABAAAAIAAAAACPTkDJAAAFJElEQVRYCbVXTWgVVxT+7uRPUxA1VmNIMItCMdBmk6JdaIwpNgZUCroSV26sP4tWxZ/aWFJKq0gQxWThTiwILopZRCuG5OmigWRj1Wqxu9SkoXaRaJ5a9U3Pd+/MvDt3Ju89W3pgZu655/eee8+5Z4AA/NbWJxyW8+WvXetjxw74Ap6mtLcDO3eSBsWX5uAgBOE657e1fUxcuVRPc61cCdTX66G2gvv3NcKXUdre/j5evrwdzQYDdfOmohvnImImA1RWAvwK0J4Hz+sPBIA9e4B9+yKUA22CA9c7TRQTeYZt2yoxNXUHSk2oTKaNDATNkCYtjL8LYwOdfGp4g/f27Wbg+/X+unW1noT0LT1TLiGg9xLlCHK5SRMpzgwOAs+eAffuRXQO8gytrcDdu0BLS4xBiQ/dYubL2GyA6EiKp13i8UiCwfOWcy6KAxF/48YqZLN/RY5z0gWlvtZKg3kTJwrOzj53eQvi4pUo+tDzuQNpwtyk5mZgYABoagL681uuFfv+atmgaU9vn22qqsrEe3gYcookjhLIvj7g4UObKxwv8Lj3Iaa/L14AHR3Ac1kRBbkvtO6EP5QxMWAepcHChcCVK8CrV8CWLcDT+KkR433RLsh+jkv0TeKkKXPnZBvV8PAfkYKQzgOIXO5XwReEc9GXFjOZ3REug4QCEnWO53KHZdgiXk2IqzewdOln6vLlv0m3Iaag0LHUQkrNorq6Rl29KpE2ECl4oxhUVDSrwcGfqUJniwj/9EYBlBKkjz0V6KDJqTIOOe/GRuDAAcj6HYKgzBkBL4h4kmG3BLu6Gli/HqitBRoa4jxSKZgGZV+tWPFtnCJYVxewaROwWhzj+OxZc5hGR+Os2WyNqbX29MmTwKpVkAQDuruBM2cMtbfX5jJj3/9IBzFG4Zmn8JEjeeENG+RwpJx2peqSCrhmV/j6dWDr1pidABlLKiDltlwU9IKWKUxwE4lznvcd01lyNgU6O00MSGJRuXYtwaSGhn7UJzG19CfYnYmgNpolBBXWYZkbNfeK7G9wlJnXsp7lc0tYFFNMo1MVJVPIInnRK1v2aYhb3xkx8q42Zk0mFFg0PQxypUuU8tpLFhlXwOAzsjnfy9PtGnTZUx0IjP4gRtOT1NVSDOfNqNQnac7EHAgMj4rh0mtzMeM2XTZfHPnAdiRKBF1NpV/434zTES5MbGhbgWM6AgUOju1/fLxoEXDhgpyK4Fgwc1k7x8eBJ7r1jPO7WHBBKX0BvX6dTFNXgDhv+YMHAWY5m6lDh8ztz0LF/vHECWD+fOD8eeDixTQN8bmyso5yCcnh+GwKVlEBHD8OrFmTJ+ZyxjhpoeGQyiiUAmKb9Vzq7xzABunUqXhbwlDv329W3NNjVhyKj40BR48CbG9KgxY2sfHeyBZkaGns2DFgehrYtcuU+tOnzV3BcBNomJX70SPg0iWALVEpILZZzW8U5b11C9i82ayWN968eUaEVyQNT8gaeG2w/1q8GAhb9WKKxbbS/enU1J/CW2qVAxobgclJYO9e45ht6MED88/ChrAwzGDZsrdNGrKf8/3f5DH/FIUF41R2HjU1xqHiRo0sOzSl3mFB0oVIVyZp2WRyJK69BCybNblfuvER3R7yBhTQEbDNyOX8njgyoKuWTfivY5Zh3++UX6c7tqqEAyEx+GP6QvDP/9XWUBFDDfTIir+xG2KSQpjTgZAh/OrD+vhxmxQuSQc0iVN1YqBO000Lz3T+RaplP5YsGUpr5UNd9vcfsm7uPPXRNBYAAAAASUVORK5CYII="
        />
        <title key="title">{metaTitle}</title>
        <meta name="description" content={metaDescription} key="description" />
        <meta property="og:title" content={metaTitle} key="og:title" />
        <meta property="og:description" content={metaDescription} key="og:description" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/images/instabox-banner.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="628" />
        <link key="canonical" rel="canonical" href={canonicalUrl} />
        {noIndex && <meta name="robots" content="noindex" />}
      </Head>
      <IntlProvider locale={locale} onError={error => captureException(error)} messages={messages}>
        <Styles>
          <PageTracking>
            <Header pageLinks={pageLinks} />
            <BannerContainer
              banners={banners}
              position={pathname === '/' ? 'fixed-top' : 'relative'}>
              {fullBleed ? (
                <Component {...pageProps} />
              ) : (
                <PageContainer variant={pageContainerVariant}>
                  <Container>
                    <Component {...pageProps} />
                  </Container>
                </PageContainer>
              )}
              {market && <Footer {...market} />}
            </BannerContainer>
          </PageTracking>
        </Styles>
      </IntlProvider>
    </PlausibleProvider>
  )
}

export default MyApp
