var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"93ff62bb"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* eslint-disable jest/require-hook */
import * as Sentry from '@sentry/nextjs'

import { ENV_CONFIG } from './src/constants/envConfig'

Sentry.init({
  enabled: ENV_CONFIG.NODE_ENV == 'production',
  dsn: ENV_CONFIG.SENTRY_DSN,
  environment: ENV_CONFIG.NODE_ENV,
  enableTracing: false,
  beforeSend: event => {
    if (
      event.exception?.values?.[0].value.includes(
        'Invariant: attempted to hard navigate to the same URL',
      )
    ) {
      return null
    }
    return event
  },
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    'TabId is undefined',
    "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
  ],
  denyUrls: [
    /googletagmanager\.com/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
})
