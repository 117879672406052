import { useEffect, type PropsWithChildren } from 'react'

import useAnalytics from '../../hooks/useAnalytics'

export default function PageTracking({ children }: PropsWithChildren) {
  usePageTracking()

  return <>{children}</>
}

function usePageTracking() {
  const sendAnalyticsEvent = useAnalytics()

  useEffect(() => {
    sendAnalyticsEvent('pageview')
  }, [sendAnalyticsEvent])
}
